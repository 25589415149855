<template>
  <div class="page1">
    <EForm
            :formColumns="formColumns"
            :rowSize="4"
            :optionsBtn="true"
            :actionBtn="false"
            :formData="searchForm"
            ref="form"
            @onSearch="onSearch"
            :searchFlag="true"
            :exportShow="false"
    >
    </EForm>
    <!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
    <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
            :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="true"
            :count="count"
            @changePage="changePage"
            @changeSize="changeSize"
            :page="searchForm.current"
            :page_size="searchForm.size"
    >
      <div slot="type" slot-scope="scope">
          <span v-if="scope.data.type==1">
              卖货
          </span>
        <span  v-if="scope.data.type==2">
            买货
          </span>
        <span  v-if="scope.data.type==3">
            轿车进场
          </span>

      </div>
      <div slot="status" slot-scope="scope">
<!--        1未进场 2已进场 3已删除 4已离场-->
          <span v-if="scope.data.status==1">
              未进场
          </span>
        <span  v-if="scope.data.status==2">
            已进场
          </span>
        <span  v-if="scope.data.status==3">
            已删除
          </span>
        <span  v-if="scope.data.status==4">
            已离场
          </span>
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '车牌号',
            prop: 'carNum',
          },
          {
            label: '预约手机号',
            prop: 'certificatePhone',
          },
          {
            label: '预约人',
            prop: 'accountName',
          },
          {
            label: '预约进场时间',
            prop: 'subscribeTime',
          },
          {
            label: '进场目的',
            prop: 'typeCn',
            // type:'slot'
          },
          {
            label: '进门车道',
            prop: 'enterLane',
          },
          {
            label: '出门车道',
            prop: 'outLane',
          },
          {
            label: '进门时间',
            prop: 'stratTime',
          },
          {
            label: '出门时间',
            prop: 'endTime',
          },
          {
            label: '收费车型',
            prop: 'feeCarType',
          },
          {
            label: '状态',
            prop: 'statusCn',
            // type:'slot'
          },
        ],
        formColumns: [
          {
            title: '车牌号',
            type: 'text',
            property: 'carNum',
            show: true,
          },

          {
            title: '手机号  ',
            type: 'text',
            property: 'mobile',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'cardNo',
            show: true,
          },
          {
            title: '状态',
            type: 'select',
            property: 'status',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '未进场',
                value: 1,
              },
              {
                label: '已进场',
                value: 2,
              },
              {
                label: '已删除',
                value: 3,
              },
              {
                label: '已离场',
                value: 4,
              },
            ],
          },
          {
            title: '进场目的 ',
            type: 'select',
            property: 'type',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '卖货',
                value: 1,
              },
              {
                label: '买货',
                value: 2,
              },
              {
                label: '轿车进场',
                value: 3,
              },
            ],
          },
          {
            title: '进门起始时间',
            type: 'datePicker',
            property: 'stratTimeStart',
            show: true,
          },
          {
            title: '进门截止时间',
            type: 'datePicker',
            property: 'stratTimeEnd',
            show: true,
          },
          {
            title: '进门车道',
            type: 'select',
            property: 'enterLane',
            show: true,
            options: [{
              label: '全部',
              value: '',
            }],
          },
          {
            title: '收费车型',
            type: 'select',
            property: 'feeCarType',
            show: true,
            options: [{
              label: '全部',
              value: '',
            }],
          },
          {
            title: '出门起始时间',
            type: 'datePicker',
            property: 'endTimeStart',
            show: true,
          },
          {
            title: '出门截止时间',
            type: 'datePicker',
            property: 'endTimeEnd',
            show: true,
          }, {
            title: '出门车道',
            type: 'select',
            property: 'outLane',
            show: true,
            options: [{
              label: '全部',
              value: '',
            }],
          },
          {
            title: '预约人',
            type: 'text',
            property: 'accountName',
            show: true,
          },
        ],
        tableData: [],
        searchForm: {
          accountName: null,
          outLane: null,
          feeCarType: null,
          enterLane: null,
          type: null,
          status: null,
          cardNo: null,
          carNum: null,
          endTimeStart: null,
          endTimeEnd: null,
          stratTimeStart: null,
          stratTimeEnd: null,
          mobile:null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();
      this.getCarLabel()
      this.getLaneLabel()

    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {


      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },

      async getData() {

        let res = await Http.carSubscribeList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
        }
      },
      //收费车型
      async getCarLabel() {
        let res = await Http.getCarLabel();
        if (res.code == 200) {
          this.formColumns[8].options = res.data
        }
      },
      //进门车道
      async getLaneLabel() {
        let res = await Http.getLaneLabel();
        if (res.code == 200) {
          this.formColumns[7].options = res.data
          this.formColumns[11].options = res.data
        }
      },

    }
  };
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }
</style>
